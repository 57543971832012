<template>
  <section v-if="eventOdds !== null" id="event-odds">
    <div class="row m-0">
      <div v-for="(item, groupIndex) in eventOdds" :key="groupIndex"  class="col-sm-6 col-12">
        <span class="text-left d-block odds-group-title odds-header">
          {{ item.group_name }}
        </span>
        <div class="row">
          <div class="col-12 text-left">
            <div class="d-inline-block mr-2 mb-2 mt-2" v-for="(odd, oddIndex) in item.odds"
                 :key="oddIndex">
              <span class="d-block text-center odd-desc">
                {{ odd.odds_desc }}
              </span>
              <span @click="openBetPopup(groupIndex, oddIndex)" v-if="odd.status"
                    :class="`odd_${odd.winning_status}`"
                    class="d-block text-center odd-value">
                {{ odd.odds_value }}
              </span>
              <span v-else class="d-block text-center odd-value">
                <img src="@/assets/img/locked.png" height="14">
              </span>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import { mapState } from 'vuex';

export default {
  name: 'Odds',
  props: ['eventId', 'eventInfo'],
  computed: {
    ...mapState({
      eventOdds: (state) => state.eventList.eventOdds,
      selectedBet: (state) => state.eventBet.selectedBet,
    }),
  },
  created() {
    this.getOdds();
  },
  methods: {
    getOdds() {
      this.$store.dispatch('eventList/getEventOdds', this.eventId);
    },
    openBetPopup(groupIndex, oddIndex) {
      if (this.eventInfo.status === 'NS') {
        const selectedBet = {
          is_live: false,
          event: this.eventInfo,
          odd: this.eventOdds[groupIndex].odds[oddIndex],
        };

        selectedBet.odd.odds_group_desc = this.eventOdds[groupIndex].group_name;

        this.$store.dispatch('eventBet/setSelectedBet', selectedBet);
        this.$bvModal.show('betModal');
      }
    },
  },
};
</script>

<style scoped>
</style>
