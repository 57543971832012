import Vue from 'vue';
import HttpService from '@/services/http-service';

export default {
  namespaced: true,
  state: {
    loginObject: {
      email: '',
      password: '',
    },
    loggedUser: {},
    isUserLoggedIn: false,
  },
  getters: {
    loggedUser(state) {
      return state.loggedUser;
    },
    loginObject(state) {
      return state.loginObject;
    },
    isUserLoggedIn(state) {
      return state.isUserLoggedIn;
    },
  },
  actions: {
    updateLoggedInStatus({ commit }, data) {
      commit('SET_LOGGED_IN_STATUS', data);
    },
    loginUser({ commit }, data) {
      return HttpService.post(`${process.env.VUE_APP_API_URL}/api/v1/user/panel-login`, data)
        .then((res) => {
          if (res.status === 'APPROVED') {
            commit('SET_LOGGED_USER', res.data.user);
            commit('SET_LOGGED_IN_STATUS', true);
            Vue.prototype.$session.set('ntjwt', res.data.token);
          }
        })
        .catch(() => {
          commit('SET_LOGGED_USER', {});
          commit('SET_USER_TOKEN', '');
          commit('SET_LOGGED_IN_STATUS', false);
        });
    },
    validateToken({ commit }) {
      return HttpService.post(`${process.env.VUE_APP_API_URL}/api/v1/user/validate-panel-token`, {})
        .then((res) => {
          if (res.status === 'APPROVED') {
            commit('SET_LOGGED_USER', res.data.user);
            commit('SET_LOGGED_IN_STATUS', true);
          } else {
            commit('SET_LOGGED_USER', {});
            commit('SET_USER_TOKEN', '');
            commit('SET_LOGGED_IN_STATUS', false);
          }
        })
        .catch(() => {
          commit('SET_LOGGED_USER', {});
          commit('SET_USER_TOKEN', '');
          commit('SET_LOGGED_IN_STATUS', false);
        });
    },
  },
  mutations: {
    SET_LOGGED_USER: (state, data) => {
      state.loggedUser = data;
    },
    SET_USER_TOKEN: (state, data) => {
      state.token = data;
    },
    SET_LOGGED_IN_STATUS: (state, data) => {
      state.isUserLoggedIn = data;
    },
  },
};
