<template>
  <div v-if="eventDetails !== null" class="row m-0">
    <div v-for="(item, index) in eventDetails[0]" :key="index"
         class="col-12 live-item"
         :class="{ 'text-left': item.team === 'home', 'text-right': item.team === 'away' }">
      <span class="assist">{{ item.assist }}</span>
      <span>{{ item.player }}</span>
      <span class="detail-minute">{{ item.minute }}'</span>
      <span v-if="item.detail === 'Yellow Card'">
        <svg width="16" height="14" viewBox="0 0 6 8" fill="#ffa500" style="margin-top: 1px;">
          <title>Sarı kart</title>
          <rect x="0" y="0" width="6" height="8" rx="1" ry="1"></rect>
        </svg>
      </span>
      <span v-if="item.detail === 'Red Card'">
        <svg width="16" height="14" viewBox="0 0 6 8" fill="#c1272d" style="margin-top: 1px;">
          <title>Kırmızı kart</title>
          <rect x="0" y="0" width="6" height="8" rx="1" ry="1"></rect>
        </svg>
      </span>
      <span v-if="item.detail !== null && item.detail.indexOf('Substitution') >= 0">
        <svg width="16" height="16" viewBox="0 0 1024 1024" fill="#006dcc"
             style="min-width: 16px; display: inline-block;">
          <title>Oyuncu Değişikliği</title>
          <path d="M230.912 526.816c-10.272-20.352-16.224-49.632-16.224-73.952 0-83.136
          67.552-156.992
          150.72-156.992h308.704v134.368l258.176-167.36-258.176-167.424v119.328h-308.704c-133.344
          0-241.792
          109.76-241.792 243.072 0 42.592 11.104 83.232 30.592 118.048l76.704-49.088zM793.056
          497.184c10.304
          20.416 16.224 49.696 16.224 74.016 0 83.104-67.552 156.992-150.656
          156.992h-308.8v-134.432l-258.144
          167.424 258.144 167.36v-119.264h308.8c133.28 0 241.76-109.76 241.76-243.072
          0-42.624-11.168-83.232-30.624-118.048l-76.704 49.024z"></path>
        </svg>
      </span>
      <span v-if="item.detail !== null && item.detail.indexOf('Goal') >= 0">
        <svg v-if="item.detail === 'Own Goal'"
             width="16" height="16" viewBox="0 0 1050 1024" fill="#c1272d"
             style="min-width: 16px; display: inline-block;">
          <title>Kendi Kalesine Gol</title>
          <path d="M208.928 512.352c-107.904 107.936-107.904 283.52 0 391.456s283.52
          107.936 391.392 0c107.904-107.936 107.904-283.52
          0-391.456-107.872-107.872-283.488-107.872-391.392
          0zM556.256 859.552c-67.328 67.36-159.584 80.32-240.096
          39.008l17.44-41.952-68.096-68.192-42.112
          17.44c-41.248-80.512-28.224-181.92 39.136-249.28 67.36-67.296 159.552-80.352
          240.064-39.072l-17.248 41.44 68.16 68.224 41.632-17.312c41.536 80.544 28.672
          182.208-38.88 249.696zM419.744 723.168l-23.168 107.104 90.272 34.848
          74.88-74.848-35.936-91.264zM252.288 625.248l37.184 88.608 102.336-18.624
          18.88-101.92-88.864-37.568zM895.744
          343.168c-13.536-32.032-32.928-60.768-57.6-85.44-24.704-24.608-88.128-78.528-190.24-78.528h-314.752v-122.016l-233.28 166.24
          233.28 166.176v-108.032h314.752c88.096
          0 168.096 70.56 168.096 168.416 0 63.264-26.816 110.624-78.016 142.016 0 0
          28.64 93.504 32 92 32.032-13.504 43.648-21.376 68.32-46.048 24.64-24.64
          43.936-53.408 57.504-85.44 13.984-33.088 21.152-68.288 21.152-104.64
          0.032-36.288-7.2-71.488-21.216-104.704z"></path>
        </svg>
        <svg v-else width="16" height="16" viewBox="0 0 1024 1024" fill="#52b030"
             style="min-width: 16px; display: inline-block;">
          <title>Gol</title>
          <path d="M787.968 236.064c-152.16-152.16-399.744-152.16-551.936 0-152.16
          152.128-152.16 399.776 0 551.936 152.16 152.096 399.744 152.16 551.936 0
          152.16-152.16 152.16-399.808 0-551.936zM734.592 734.624c-99.040 98.912-248.096
          118.048-366.4 57.376l28.064-66.144-98.080-98.112-66.208
          28.064c-60.608-118.368-41.568-267.36 57.44-366.4 98.976-98.912 247.872-118.048
          366.272-57.44l-27.776 65.376 98.144 98.144 65.664-27.808c61.056 118.432 42.016
          267.744-57.12 366.944zM549.632 549.664l-32.608 150.912 127.2 49.216
          105.6-105.6-50.72-128.576zM284.576 382.656l52.544 124.928
          144.256-26.176 26.688-143.776-125.472-52.992z"></path>
        </svg>
      </span>
      <span v-if="item.detail === 'Missed Penalty'">
        <svg width="16" height="16" viewBox="0 0 1024 1024" fill="#c1272d"
             style="min-width: 16px; display: inline-block;">
          <title>Penaltı kaçırıldı</title>
          <path d="M687.904 786.496l-110.976-105.28 110.912-105.12-61.472-64.832-114.368
          108.352-114.368-108.352-61.472 64.832 110.912 105.12-110.912 105.28 61.472
          64.864 114.368-108.48 114.368 108.48zM116.352
          162.912v349.088h93.12v-256h605.056v256h93.12v-349.088z"></path>
        </svg>
      </span>
      <span v-if="item.detail === 'Penalty'">
        <svg width="16" height="16" viewBox="0 0 1024 1024" fill="#52b030"
             style="min-width: 16px; display: inline-block;">
          <title>Penaltı</title>
          <path d="M328.96 488.928c-100.928 100.96-100.928 265.216 0
          366.176s265.216 100.96 366.080 0c100.928-100.96 100.928-265.216
          0-366.176-100.896-100.864-265.152-100.864-366.080 0zM653.856
          813.728c-62.976 63.008-149.28 75.136-224.576 36.48l16.32-39.232-63.68-63.776-39.392
          16.32c-38.592-75.328-26.4-170.176 36.608-233.184 63.008-62.944 149.248-75.168
          224.576-36.544l-16.128 38.784 63.744 63.808 38.944-16.192c38.784 75.296 26.72
          170.4-36.416 233.536zM526.144 686.144l-21.664 100.16 84.416 32.608
          70.048-69.984-33.6-85.408zM369.504 594.528l34.784 82.88 95.712-17.376
          17.664-95.36-83.104-35.136zM116.352 162.912v349.088h93.12v-256h605.056v256h93.12v-349.088z"></path>
        </svg>
      </span>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex';

export default {
  name: 'LiveDetails',
  props: ['eventId'],
  computed: {
    ...mapState({
      eventDetails: (state) => state.eventList.eventDetails,
    }),
  },
  data() {
    return {
      interval: '',
    };
  },
  created() {
    this.getLiveDetails();
    this.interval = window.setInterval(() => this.getLiveDetails(), 60000);
  },
  beforeDestroy() {
    clearInterval(this.interval);
  },
  methods: {
    getLiveDetails() {
      this.$store.dispatch('eventList/getEventDetails', this.eventId);
    },
  },
};
</script>

<style scoped>
</style>
