<template>
  <div class="feed-list">
    <div class="container-fluid">
      <div class="row">
        <div class="col-12 bulletin-date">
          <span class="d-inline-block mr-2">Tarih Seç: </span>
          <date-pick
            v-model="date"
            :format="'YYYY-MM-DD'"
          ></date-pick>
        </div>
      </div>
      <div class="row event-list-section mb-5 mt-3 position-relative">
        <div id="event-list" class="col-12">
          <div class="row m-0">
            <div class="col-12 league-header text-left">
              <span class="title">Tahminlerim</span>
            </div>
            <div v-if="feedList.length < 1" class="col-12 mt-5">
              <span>Bugüne ait tahmin bulunamadı.</span>
            </div>
          </div>
          <div class="feed-detail" v-for="(item, index) in feedList" :key="index">
            <div class="row m-0" v-if="!isMobile">
              <div class="col-2 text-left">
                <span class="country-flag mr-2"><img :src="item.country_flag"></span>
                <span class="title">{{ item.league_name }}</span>
                <span class="minute d-block">
                  <span class="d-inline-block mr-2">{{ item.event_date | moment('DD/MM') }}</span>
                  <span class="d-inline-block font-weight-bold">{{ item.event_date | moment('HH:mm') }}</span>
                </span>
              </div>
              <div class="col-6 text-left">
                  <span v-if="item.is_live_bet" class="d-inline-block pr-2">
                    <img height="16" class="pr-1" src="@/assets/img/live_bet.png"/>
                    '{{ item.event_info.current_minute }}
                  </span>
                  <span class="team-name">
                    <span class="team-logo"><img :src="item.home_team_logo"></span>
                    {{ item.home_team_name }}
                    <span class="pl-2 font-weight-bold"
                          v-if="item.is_live_bet">{{ item.event_info.home_team_score }}</span>
                    <span> - </span>
                    <span class="pr-2 font-weight-bold"
                          v-if="item.is_live_bet">{{ item.event_info.away_team_score }}</span>
                    <span class="team-logo"><img :src="item.away_team_logo"></span>
                    {{ item.away_team_name }}
                  </span>
                  <span v-if="item.is_vip" class="d-inline-block">
                    <img class="pr-1" src="@/assets/img/vip_icon.png"/>
                  </span>
                  <span class="bet-detail d-block mb-1 mt-1 p-1">
                    {{ item.group_name }} {{ item.odds_group_desc }}: <b>{{ item.odds_desc }}</b>
                    <span class="pl-2 font-weight-bold" v-if="item.stake !== null">({{ item.stake }}/10)</span>
                  </span>
                  <span v-if="item.feed_comment.length > 0" class="feed-comment d-block">
                    <b-icon icon="chat-right-text" style="color: #046680;"></b-icon>
                    {{ item.feed_comment }}
                  </span>
              </div>
              <div class="col-2 text-center">
                <span class="mr-2">{{ item.odd_value }}</span>
                <span v-if="item.winning_status === 'W'">
                    <svg viewBox="0 0 24 24" width="18" height="18" fill="#52b030"
                         class="styles__IconWDL-sc-172owur-4 blaPHz">
                      <path d="M24,12c0,6.6-5.4,12-12,12C5.4,24,0,18.6,0,12S5.4,0,12,0C18.6,0,24,5.4,24,12z
                      M15.7,16.9l2.1-9.8h-2.3
                      l-1.1,6.1L13,7.1h-2l-1.3,6.1L8.5,7.1H6.2l2.1,9.8h2.4l1.3-5.6l1.3,5.6H15.7L15.7,16.9z">
                      </path>
                    </svg>
                  </span>
                <span v-else-if="item.winning_status === 'L'">
                    <svg viewBox="0 0 24 24" width="18" height="18" fill="#c1272d"
                         class="styles__IconWDL-sc-172owur-4 blaPHz">
                      <path d="M24,12c0,6.6-5.4,12-12,12C5.4,24,0,18.6,0,12S5.4,0,12,0C18.6,0,24,5.4,24,12z
                      M15.9,17.5v-2.1h-4.7v-9H8.5v11.1L15.9,17.5 L15.9,17.5z">
                      </path>
                    </svg>
                  </span>
                <span v-else></span>
              </div>
              <div class="col-2 event-btn text-right">
                <b-button @click="openEventDetailWeb(item.id)" variant="info">Detay</b-button>
              </div>
            </div>
            <div class="row m-0" v-if="isMobile">
              <div class="col-2 text-left">
                <span class="country-flag mr-2"><img :src="item.country_flag"></span>
                <span class="minute d-block">
                  <span class="d-inline-block mr-2">{{ item.event_date | moment('DD/MM') }}</span>
                  <span class="d-inline-block font-weight-bold">{{ item.event_date | moment('HH:mm') }}</span>
                </span>
                <span v-if="item.is_vip" class="d-block">
                  <img class="pr-1" src="@/assets/img/vip_icon.png"/>
                </span>
                <span v-if="item.is_live_bet" class="d-block">
                  <img height="16" class="pr-1" src="@/assets/img/live_bet.png"/>
                </span>
              </div>
              <div class="col-6 text-left">
                <span class="team-name d-block">
                  <span class="team-logo"><img :src="item.home_team_logo"></span>
                  {{ item.home_team_name }}
                  <span class="pl-2 font-weight-bold"
                        v-if="item.is_live_bet">{{ item.event_info.home_team_score }}</span>
                </span>
                <span class="team-name d-block">
                  <span class="team-logo"><img :src="item.away_team_logo"></span>
                  {{ item.away_team_name }}
                  <span class="pl-2 font-weight-bold"
                        v-if="item.is_live_bet">{{ item.event_info.away_team_score }}</span>
                </span>
                <span class="bet-detail d-block mb-1 mt-1 p-1">
                  {{ item.group_name }} {{ item.odds_group_desc }}: <b>{{ item.odds_desc }}</b>
                  <span class="pl-2 font-weight-bold" v-if="item.stake !== null">({{ item.stake }}/10)</span>
                </span>
                <span v-if="item.feed_comment.length > 0" class="feed-comment d-block">
                  <b-icon icon="chat-right-text" style="color: #046680;"></b-icon>
                  {{ item.feed_comment }}
                </span>
              </div>
              <div class="col-2 text-center">
                <span class="mr-2">{{ item.odd_value }}</span>
                <span v-if="item.winning_status === 'W'">
                    <svg viewBox="0 0 24 24" width="18" height="18" fill="#52b030"
                         class="styles__IconWDL-sc-172owur-4 blaPHz">
                      <path d="M24,12c0,6.6-5.4,12-12,12C5.4,24,0,18.6,0,12S5.4,0,12,0C18.6,0,24,5.4,24,12z
                      M15.7,16.9l2.1-9.8h-2.3
                      l-1.1,6.1L13,7.1h-2l-1.3,6.1L8.5,7.1H6.2l2.1,9.8h2.4l1.3-5.6l1.3,5.6H15.7L15.7,16.9z">
                      </path>
                    </svg>
                  </span>
                <span v-else-if="item.winning_status === 'L'">
                    <svg viewBox="0 0 24 24" width="18" height="18" fill="#c1272d"
                         class="styles__IconWDL-sc-172owur-4 blaPHz">
                      <path d="M24,12c0,6.6-5.4,12-12,12C5.4,24,0,18.6,0,12S5.4,0,12,0C18.6,0,24,5.4,24,12z
                      M15.9,17.5v-2.1h-4.7v-9H8.5v11.1L15.9,17.5 L15.9,17.5z">
                      </path>
                    </svg>
                  </span>
                <span v-else></span>
              </div>
              <div class="col-2 event-btn text-right">
                <b-button @click="openEventDetail(item.id)" variant="info">Detay</b-button>
              </div>
            </div>
          </div>
        </div>
        <div v-if="selectedEvent > 0" :key="selectedEvent" class="col-lg-4 col-sm-12 event-detail-section">
          <eventDetail v-on:close="closeEventDetail"
                       :eventId="selectedEvent" :isLiveEvent="true"></eventDetail>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import DatePick from 'vue-date-pick';
import eventDetail from '@/components/event/Detail.vue';

export default {
  name: 'FeedList',
  components: { eventDetail, DatePick },
  mounted() {
    this.getFeedList();
  },
  async beforeMount() {
    if (!this.isUserLoggedIn) {
      await this.$router.push('/login');
    }
  },
  watch: {
    date: {
      handler() {
        this.getFeedList();
      },
    },
  },
  data() {
    return {
      selectedEvent: 0,
      date: new Date().toISOString().slice(0, 10),
    };
  },
  computed: {
    ...mapState({
      feedList: (state) => state.eventBet.feedList,
      isMobile: (state) => state.isMobile,
      isUserLoggedIn: (state) => state.user.isUserLoggedIn,
    }),
  },
  methods: {
    closeEventDetail() {
      document.getElementById('event-list').className = 'col-12';
      this.selectedEvent = 0;
    },
    getFeedList() {
      this.$store.dispatch('eventBet/getFeedByUser', this.date);
    },
    openEventDetail(eventId) {
      document.getElementById('event-list').className = 'col-12 hide-behind';
      this.selectedEvent = eventId;
      window.scrollTo(0, 0);
    },
    openEventDetailWeb(eventId) {
      document.getElementById('event-list').className = 'col-lg-8';
      this.selectedEvent = eventId;
      window.scrollTo(0, 0);
    },
  },
};
</script>

<style scoped>

</style>
