<template>
  <div>
    <div v-if="eventLineups !== null && typeof eventLineups.starting_players !== 'undefined'"
         class="row">
      <div class="col-6">
        <span class="lineups-title d-block mb-2">İLK 11</span>
        <span class="d-block">
          <span class="font-weight-bold" v-if="typeof eventLineups.coach !== 'undefined'">
            Coach: {{ eventLineups.coach.home }}
          </span>
          <span v-if="typeof eventLineups.formation !== 'undefined'">
            ({{ eventLineups.formation.home }})
          </span>
        </span>
        <span class="d-block text-left player-item"
              v-for="(item, index) in eventLineups.starting_players.home"
              :key="index">
          {{ item.player.number }}
          <span class="player-position">{{ item.player.pos }}</span>
          {{ item.player.name }}
        </span>
      </div>
      <div class="col-6">
        <span class="lineups-title d-block mb-2">İLK 11</span>
        <span class="d-block">
          <span class="font-weight-bold" v-if="typeof eventLineups.coach !== 'undefined'">
            Coach: {{ eventLineups.coach.away }}
          </span>
          <span v-if="typeof eventLineups.formation !== 'undefined'">
            ({{ eventLineups.formation.away }})
          </span>
        </span>
        <span class="d-block text-left player-item"
              v-for="(item, index) in eventLineups.starting_players.away"
              :key="index">
          {{ item.player.number }}
          <span class="player-position">{{ item.player.pos }}</span>
          {{ item.player.name }}
        </span>
      </div>
    </div>
    <div v-if="eventLineups !== null && typeof eventLineups.substitutes !== 'undefined'"
         class="row">
      <div class="col-6">
        <span class="lineups-title d-block mb-2">YEDEKLER</span>
        <span class="d-block text-left player-item"
              v-for="(item, index) in eventLineups.substitutes.home"
              :key="index">
          {{ item.player.number }}
          <span class="player-position">{{ item.player.pos }}</span>
          {{ item.player.name }}
        </span>
      </div>
      <div class="col-6">
        <span class="lineups-title d-block mb-2">YEDEKLER</span>
        <span class="d-block text-left player-item"
              v-for="(item, index) in eventLineups.substitutes.away"
              :key="index">
          {{ item.player.number }}
          <span class="player-position">{{ item.player.pos }}</span>
          {{ item.player.name }}
        </span>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex';

export default {
  name: 'Lineups',
  props: ['eventId'],
  computed: {
    ...mapState({
      eventLineups: (state) => state.eventList.eventLineups,
    }),
  },
  created() {
    this.getLineups();
  },
  methods: {
    getLineups() {
      this.$store.dispatch('eventList/getEventLineups', this.eventId);
    },
  },
};
</script>

<style scoped>
</style>
