import HttpService from '../../services/http-service';

export default {
  namespaced: true,
  state: {
    liveEventList: [],
    eventBulletin: [],
    eventResultList: [],
    eventInfo: {},
    eventDetails: [],
    eventStatistics: [],
    eventLineups: {},
    eventOdds: [],
    eventTypes: [],
  },
  getters: {
    getEventBulletin(state) {
      return state.eventBulletin;
    },
    getEventLineups(state) {
      return state.eventLineups;
    },
    getEventStatistics(state) {
      return state.eventStatistics;
    },
    getEventDetails(state) {
      return state.eventDetails;
    },
    getEventInfo(state) {
      return state.eventInfo;
    },
    getLiveEvents(state) {
      return state.liveEventList;
    },
    getEventResults(state) {
      return state.eventResultList;
    },
    getEventOdds(state) {
      return state.eventOdds;
    },
    eventTypes(state) {
      return state.eventTypes;
    },
  },
  actions: {
    getEventBulletin({ commit }, data) {
      return HttpService.get(`${process.env.VUE_APP_API_URL}/api/v1/events/bulletin/${data.eventType}/${data.date}/[${data.odds}]`, {})
        .then((res) => {
          if (res.status === 'APPROVED') {
            commit('setEventBulletin', res.data);
          }
        })
        .catch((err) => {
          commit('CHANGE_ALERT_MESSAGE', { alertMessage: err, type: 'danger' }, { root: true });
        });
    },
    getEventTypes({ commit }) {
      return HttpService.get(`${process.env.VUE_APP_API_URL}/api/v1/events/types`, {})
        .then((res) => {
          if (res.status === 'APPROVED') {
            commit('SET_EVENT_TYPES', res.data);
          }
        })
        .catch((err) => {
          commit('CHANGE_ALERT_MESSAGE', { alertMessage: err, type: 'danger' }, { root: true });
        });
    },
    getLiveEvents({ commit }, eventType) {
      return HttpService.get(`${process.env.VUE_APP_API_URL}/api/v1/events/live/${eventType}`, {})
        .then((res) => {
          if (res.status === 'APPROVED') {
            commit('setLiveEvents', res.data);
          }
        })
        .catch((err) => {
          commit('CHANGE_ALERT_MESSAGE', { alertMessage: err, type: 'danger' }, { root: true });
        });
    },
    getEventResults({ commit }) {
      return HttpService.get(`${process.env.VUE_APP_API_URL}/api/v1/events/results/1/2020-12-24`, {})
        .then((res) => {
          if (res.status === 'APPROVED') {
            commit('setEventResults', res.data);
          }
        })
        .catch((err) => {
          commit('CHANGE_ALERT_MESSAGE', { alertMessage: err, type: 'danger' }, { root: true });
        });
    },
    getEventInfo({ commit }, id) {
      return HttpService.get(`${process.env.VUE_APP_API_URL}/api/v1/events/info/${id}`, {})
        .then((res) => {
          if (res.status === 'APPROVED') {
            commit('setEventInfo', res.data[0]);
          }
        })
        .catch((err) => {
          commit('CHANGE_ALERT_MESSAGE', { alertMessage: err, type: 'danger' }, { root: true });
        });
    },
    getEventDetails({ commit }, id) {
      return HttpService.get(`${process.env.VUE_APP_API_URL}/api/v1/events/details/${id}`, {})
        .then((res) => {
          if (res.status === 'APPROVED') {
            commit('setEventDetails', res.data);
          }
        })
        .catch((err) => {
          commit('CHANGE_ALERT_MESSAGE', { alertMessage: err, type: 'danger' }, { root: true });
        });
    },
    getEventStatistics({ commit }, id) {
      return HttpService.get(`${process.env.VUE_APP_API_URL}/api/v1/events/statistics/${id}`, {})
        .then((res) => {
          if (res.status === 'APPROVED') {
            commit('setEventStatistics', res.data);
          }
        })
        .catch((err) => {
          commit('CHANGE_ALERT_MESSAGE', { alertMessage: err, type: 'danger' }, { root: true });
        });
    },
    getEventLineups({ commit }, id) {
      return HttpService.get(`${process.env.VUE_APP_API_URL}/api/v1/events/lineups/${id}`, {})
        .then((res) => {
          if (res.status === 'APPROVED') {
            commit('setEventLineups', res.data);
          }
        })
        .catch((err) => {
          commit('CHANGE_ALERT_MESSAGE', { alertMessage: err, type: 'danger' }, { root: true });
        });
    },
    getEventOdds({ commit }, id) {
      return HttpService.get(`${process.env.VUE_APP_API_URL}/api/v1/events/odds/${id}`, {})
        .then((res) => {
          if (res.status === 'APPROVED') {
            commit('setEventOdds', res.data);
          }
        })
        .catch((err) => {
          commit('CHANGE_ALERT_MESSAGE', { alertMessage: err, type: 'danger' }, { root: true });
        });
    },
    updateMatchOfTheDay({ commit }, eventId) {
      return HttpService.put(`${process.env.VUE_APP_API_URL}/api/v1/events/match-of-the-day`, { event_id: eventId })
        .then((res) => {
          if (res.status === 'APPROVED') {
            commit('CHANGE_ALERT_MESSAGE', { alertMessage: 'Status Changed Successfully', type: 'success' }, { root: true });
          }
        })
        .catch((err) => {
          commit('CHANGE_ALERT_MESSAGE', { alertMessage: err, type: 'danger' }, { root: true });
        });
    },
  },
  mutations: {
    setEventBulletin: (state, data) => {
      state.eventBulletin = data;
    },
    setEventLineups: (state, data) => {
      state.eventLineups = data;
    },
    setEventStatistics: (state, data) => {
      state.eventStatistics = data;
    },
    setEventDetails: (state, data) => {
      state.eventDetails = data;
    },
    setEventInfo: (state, data) => {
      state.eventInfo = data;
    },
    setEventResults: (state, data) => {
      state.eventResultList = data;
    },
    setLiveEvents: (state, data) => {
      state.liveEventList = data;
    },
    setEventOdds: (state, data) => {
      state.eventOdds = data;
    },
    SET_EVENT_TYPES: (state, data) => {
      state.eventTypes = data;
    },
  },
};
