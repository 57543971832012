<template>
  <div class="d-flex" id="app">
    <b-alert
      :show="messageDismissCountDown"
      dismissible
      :variant="messageType"
      @dismiss-count-down="dismissCountDown"
    >
      <div class="mb-2 mr-3 alert-icon">
        <b-icon class="rounded"
                v-if="messageType === 'success'" icon="check-square-fill" scale="2" variant="light"></b-icon>
        <b-icon class="rounded"
                v-else icon="exclamation-triangle-fill" scale="2" variant="dark"></b-icon>
      </div>
      <p class="p-1">{{ alertMessage }}</p>
    </b-alert>
    <div id="page-content-wrapper">
      <BettingPopup v-if="isUserLoggedIn"
                    :selectedBet="selectedBet"></BettingPopup>
      <Menu v-if="isUserLoggedIn"></Menu>
      <router-view :key="$route.fullPath" />
    </div>
  </div>
</template>

<script>
import BettingPopup from '@/components/common/BettingPopup.vue';
import { mapState, mapActions } from 'vuex';
import Menu from './components/common/Menu.vue';

export default {
  name: 'App',
  components: { BettingPopup, Menu },
  computed: {
    ...mapState({
      selectedBet: (state) => state.eventBet.selectedBet,
      isUserLoggedIn: (state) => state.user.isUserLoggedIn,
      alertMessage: (state) => state.alertMessage,
      messageType: (state) => state.messageType,
      messageDismissCountDown: (state) => state.messageDismissCountDown,
      showBettingPopup: (state) => state.showBettingPopup,
    }),
  },
  methods: {
    ...mapActions([
      'dismissCountDown',
    ]),
  },
  async beforeCreate() {
    const loggedInStatus = typeof this.$session.get('ntjwt') !== 'undefined';
    await this.$store.dispatch('user/updateLoggedInStatus', loggedInStatus);
  },
};
</script>

<style>
#app {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}
#sidebar-wrapper {
  min-height: 100vh;
  margin-left: -15rem;
  -webkit-transition: margin .25s ease-out;
  -moz-transition: margin .25s ease-out;
  -o-transition: margin .25s ease-out;
  transition: margin .25s ease-out;
}

#sidebar-wrapper .sidebar-heading {
  padding: 0.875rem 1.25rem;
  font-size: 1.2rem;
}

#sidebar-wrapper .list-group {
  width: 15rem;
}

#page-content-wrapper {
  min-width: 100vw;
}

#wrapper.toggled #sidebar-wrapper {
  margin-left: 0;
}

@media (min-width: 768px) {
  #sidebar-wrapper {
    margin-left: 0;
  }

  #page-content-wrapper {
    min-width: 0;
    width: 100%;
  }

  #wrapper.toggled #sidebar-wrapper {
    margin-left: -15rem;
  }
}
</style>
