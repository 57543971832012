<template>
  <div class="home">
    <div class="container-fluid">
      <div class="row">
        <div class="col-12 col-md-6">
          <b-nav pills>
            <b-nav-item v-for="(item, index) in eventTypes" :key="index"
                        @click="getBulletin(item.id, date, oddsTypeMapping[item.id])"
                        :active="selectedType === item.id">
              {{ item.type_name }}
            </b-nav-item>
          </b-nav>
        </div>
        <div class="col-12 col-md-6 bulletin-date">
          <span class="d-inline-block mr-2">Tarih Seç: </span>
          <date-pick
            v-model="date"
            :format="'YYYY.MM.DD'"
            :isDateDisabled="isPastDate"
          ></date-pick>
        </div>
      </div>
      <div class="row event-list-section mb-5 mt-3 position-relative">
        <div v-if="eventBulletin.length < 1 && !isLoading" class="col-12">
          <span>Bültende maç bulunmamaktadır.</span>
        </div>
        <div class="text-center d-block w-100 mb-3 justify-content-between">
          <b-spinner v-if="isLoading"
                     variant="secondary"
          ></b-spinner>
        </div>
        <div id="event-bulletin" class="col-12">
          <div v-if="!isMobile">
            <div v-for="(item, index) in eventBulletin" :key="index">
              <div class="league-header text-left">
                <span class="country-flag"><img :src="item.country_flag"></span>
                <span class="title">{{ item.country_name }} - {{ item.league_name }}</span>
              </div>
              <div v-for="(event, eventIndex) in item.events" :key="eventIndex" class="row event-detail m-0">
                <div class="col-1 text-center">
                  <span class="minute">
                    <span class="d-block">{{ event.event_date | moment('DD/MM') }}</span>
                    <span class="d-block font-weight-bold">{{ event.event_date | moment('HH:mm') }}</span>
                  </span>
                </div>
                <div class="col-3 text-left">
                  <span class="team-name d-block">
                    <span class="team-logo"><img :src="event.home_team_logo"></span>
                    {{ event.home_team_name }}
                  </span>
                  <span class="team-name d-block">
                    <span class="team-logo"><img :src="event.away_team_logo"></span>
                    {{ event.away_team_name }}
                  </span>
                </div>
                <div class="col-8 text-right">
                  <div class="d-inline-block mr-2" v-for="(odd, oddIndex) in event.event_odds"
                       :key="oddIndex">
                    <span class="d-block text-center odd-desc">
                      {{ odd.odds_group_desc }} {{ odd.odds_desc }}
                    </span>
                    <span @click="openBetPopup(index, eventIndex, oddIndex)" v-if="odd.odds_status"
                          class="d-block text-center odd-value">
                      {{ odd.odds_value.toFixed(2) }}
                    </span>
                    <span v-else class="d-block text-center odd-value">
                      <img src="@/assets/img/locked.png" height="14">
                    </span>
                  </div>
                  <div class="total-odds mr-2">
                    <span @click="openEventDetailWeb(event.id)"
                          v-if="event.total_odds > 0">+{{ event.total_odds }}</span>
                  </div>
                  <div class="d-inline-block align-bottom"
                  :class="{ 'is-active': event.match_of_the_day }">
                    <b-form-checkbox @change="updateDayOfTheMatch(event.id)"
                                     v-model="event.match_of_the_day"
                                     name="check-button" switch>
                    </b-form-checkbox>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div v-if="isMobile">
            <div v-for="(item, index) in eventBulletin" :key="index">
              <div class="league-header text-left">
                <span class="country-flag"><img :src="item.country_flag"></span>
                <span class="title">{{ item.country_name }} - {{ item.league_name }}</span>
              </div>
              <div v-for="(event, eventIndex) in item.events" :key="eventIndex" class="row event-detail m-0">
                <div class="col-12">
                  <div class="row event-name">
                    <div class="col-10 text-left">
                      <span class="team-name">
                        <span class="team-logo"><img :src="event.home_team_logo"></span>
                        {{ event.home_team_name }}
                      </span>
                      <span> - </span>
                      <span class="team-name">
                        <span class="team-logo"><img :src="event.away_team_logo"></span>
                        {{ event.away_team_name }}
                      </span>
                    </div>
                    <div class="col-2 text-right">
                      <span class="minute">
                        <span class="d-block font-weight-bold">{{ event.event_date | moment('HH:mm') }}</span>
                      </span>
                    </div>
                  </div>
                  <div v-if="event.event_odds !== null" class="row event-odds">
                    <div class="col-12 text-left">
                      <div class="d-inline-block mr-1" v-for="(odd, oddIndex) in event.event_odds"
                           :key="oddIndex">
                        <span class="d-block text-center odd-desc">
                          {{ odd.odds_group_desc }} {{ odd.odds_desc }}
                        </span>
                        <span @click="openBetPopup(index, eventIndex, oddIndex)" v-if="odd.odds_status"
                              class="d-block text-center odd-value">
                          {{ odd.odds_value.toFixed(2) }}
                        </span>
                        <span v-else class="d-block text-center odd-value">
                          <img src="@/assets/img/locked.png" height="14">
                        </span>
                      </div>
                      <div class="total-odds">
                        <span @click="openEventDetail(event.id)"
                              v-if="event.total_odds > 0">+{{ event.total_odds }}</span>
                      </div>
                      <div class="day-of-the-match">
                        <b-form-checkbox @click="updateDayOfTheMatch(event.id)"
                                        v-model="event.match_of_the_day"
                                        name="check-button" switch>
                        </b-form-checkbox>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div v-if="selectedEvent > 0" :key="selectedEvent" class="col-lg-4 col-sm-12 event-detail-section">
          <eventDetail :eventId="selectedEvent" :isLiveEvent="false"></eventDetail>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import eventDetail from '@/components/event/Detail.vue';
import DatePick from 'vue-date-pick';

export default {
  name: 'Home',
  components: { eventDetail, DatePick },
  async mounted() {
    await this.$store.dispatch('eventList/getEventTypes');
    this.getBulletin(this.eventTypes[0].id, this.date, this.oddsTypeMapping[this.eventTypes[0].id]);
  },
  async beforeMount() {
    if (!this.isUserLoggedIn) {
      await this.$router.push('/login');
    }
  },
  data() {
    return {
      date: new Date().toISOString().slice(0, 10),
      oddsTypeMapping: {
        1: '1,4',
        2: '40,49',
        3: '1',
      },
      selectedEvent: 0,
      selectedType: 1,
      isLoading: false,
    };
  },
  computed: {
    ...mapState({
      eventBulletin: (state) => state.eventList.eventBulletin,
      selectedBet: (state) => state.eventBet.selectedBet,
      isMobile: (state) => state.isMobile,
      isUserLoggedIn: (state) => state.user.isUserLoggedIn,
      eventTypes: (state) => state.eventList.eventTypes,
    }),
  },
  watch: {
    date: {
      handler() {
        this.getBulletin(this.selectedType, this.date, this.oddsTypeMapping[this.selectedType]);
      },
    },
  },
  methods: {
    getBulletin(eventType, date, odds) {
      this.isLoading = true;
      this.selectedEvent = 0;
      this.selectedType = eventType;
      this.$store.dispatch('eventList/getEventBulletin', { eventType, date, odds })
        .then(() => {
          this.isLoading = false;
        });
      document.getElementById('event-bulletin').className = 'col-12';
    },
    isPastDate(date) {
      const currentDate = new Date();
      currentDate.setDate(currentDate.getDate() - 1);
      return date < currentDate;
    },
    updateDayOfTheMatch(eventId) {
      this.$store.dispatch('eventList/updateMatchOfTheDay', eventId);
    },
    openEventDetail(eventId) {
      document.getElementById('event-bulletin').className = 'col-12 hide-behind';
      this.selectedEvent = eventId;
      window.scrollTo(0, 0);
    },
    openEventDetailWeb(eventId) {
      document.getElementById('event-bulletin').className = 'col-lg-8';
      this.selectedEvent = eventId;
      window.scrollTo(0, 0);
    },
    openBetPopup(groupIndex, eventIndex, oddIndex) {
      const selectedBet = {
        is_live: false,
        event: this.eventBulletin[groupIndex].events[eventIndex],
        odd: this.eventBulletin[groupIndex].events[eventIndex].event_odds[oddIndex],
      };

      this.$store.dispatch('eventBet/setSelectedBet', selectedBet);
      this.$bvModal.show('betModal');
    },
  },
};
</script>

<style>
</style>
