<template>
  <div v-if="leagueStanding !== null">
    <span class="text-left d-block odds-group-title odds-header mb-3">
      {{ leagueStanding.league_name }}
    </span>
    <div class="row m-0">
      <div class="col-4 text-left">Takım</div>
      <div class="col-1 p-0">O</div>
      <div class="col-1 p-0">G</div>
      <div class="col-1 p-0">B</div>
      <div class="col-1 p-0">M</div>
      <div class="col-2 p-0">AV.</div>
      <div class="col-2">P</div>
    </div>
    <div v-for="(item, index) in leagueStanding.teams" :key="index" class="row m-0 standing-item">
      <div class="col-4 text-left">
        <span :class="{
              'standing-home-team': item.team_name === eventInfo.home_team_name,
              'standing-away-team': item.team_name === eventInfo.away_team_name,
              }">
          {{ item.rank }} - {{ item.team_name }}
        </span>
      </div>
      <div class="col-1 p-0">
        <span>
          {{ item.total.played }}
        </span>
      </div>
      <div class="col-1 p-0">
        <span>
          {{ item.total.win }}
        </span>
      </div>
      <div class="col-1 p-0">
        <span>
          {{ item.total.draw }}
        </span>
      </div>
      <div class="col-1 p-0">
        <span>
          {{ item.total.lose }}
        </span>
      </div>
      <div class="col-2 p-0">
        <span>
          {{ item.total.goals.for }} - {{ item.total.goals.against }}
        </span>
      </div>
      <div class="col-2">
        <span>
          {{ item.total_point }}
        </span>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex';

export default {
  name: 'Standing',
  props: ['leagueId', 'eventInfo'],
  computed: {
    ...mapState({
      leagueStanding: (state) => state.league.leagueStanding,
    }),
  },
  created() {
    this.getLeagueStanding();
  },
  methods: {
    getLeagueStanding() {
      this.$store.dispatch('league/getStanding', { leagueId: this.leagueId, eventId: this.eventInfo.id });
    },
  },
};
</script>

<style scoped>

</style>
