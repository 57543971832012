import Vue from 'vue';
import Vuex from 'vuex';
import eventList from '@/store/modules/eventList';
import league from '@/store/modules/league';
import eventBet from '@/store/modules/eventBet';
import user from '@/store/modules/user';

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    alertMessage: '',
    messageType: '',
    messageDismissSecs: 5,
    messageDismissCountDown: 0,
    isMobile: /iPhone|iPad|iPod|Android/i.test(navigator.userAgent),
  },
  mutations: {
    CHANGE_COUNTDOWN: (state, data) => {
      state.messageDismissCountDown = data;
    },
    CHANGE_ALERT_MESSAGE(state, params) {
      state.alertMessage = params.alertMessage;
      state.messageType = params.type;
      state.messageDismissCountDown = state.messageDismissSecs;
      window.scrollTo(0, 0);
    },
  },
  actions: {
    updateAlertMessage: ({ commit }, newValue) => {
      commit('CHANGE_ALERT_MESSAGE', newValue);
    },
    dismissCountDown({ commit }, dismissCountDown) {
      commit('CHANGE_COUNTDOWN', dismissCountDown);
    },
  },
  modules: {
    eventList,
    league,
    eventBet,
    user,
  },
});
