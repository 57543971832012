<template>
  <div class="event-info">
    <div class="row">
      <div class="col-12">
        <div class="league-header text-left">
          <span class="country-flag"><img :src="eventInfo.country_flag"></span>
          <span class="title">{{ eventInfo.country_name }} - {{ eventInfo.league_name }}</span>
          <div @click="closeDetail" class="close-btn">
            <b-icon icon="x-circle" style="color: #fff;"></b-icon>
          </div>
        </div>
      </div>
    </div>
    <div class="row mt-1">
      <div class="col-4">
        {{ eventInfo.event_date | moment('HH:mm') }}
      </div>
      <div class="col-4"></div>
      <div class="col-4">
        {{ eventInfo.event_date | moment('DD-MM-YYYY') }}
      </div>
    </div>
    <div class="row mt-2">
      <div class="col-4">
        <span class="team-name">
          <span class="team-logo"><img :src="eventInfo.home_team_logo"></span>
          {{ eventInfo.home_team_name }}
        </span>
      </div>
      <div class="col-4">
        <span v-if="eventInfo.home_team_score !== null" class="minute">
          <img class="live-icon" src="@/assets/img/red_dot.gif" />
          <span v-if="eventInfo.live_status === 'HT' || eventInfo.live_status === 'FT'">
            {{ eventInfo.live_status }}
          </span>
          <span v-else>{{ eventInfo.live_status }} {{ eventInfo.current_minute }}</span>
        </span>
        <span class="event-score">
          {{ eventInfo.home_team_score }} - {{ eventInfo.away_team_score }}
        </span>
      </div>
      <div class="col-4">
        <span class="team-name">
          <span class="team-logo"><img :src="eventInfo.away_team_logo"></span>
          {{ eventInfo.away_team_name }}
        </span>
      </div>
    </div>
    <div class="row mt-3">
      <div class="col-12">
        <b-tabs content-class="mt-3">
          <b-tab v-if="isLiveEvent" title="Canlı Bilgiler" active>
            <LiveDetails v-if="eventInfo.event_type === 'football'"
                         :eventId="eventId"></LiveDetails>
            <div v-if="eventInfo.event_type === 'basketball'" class="row m-0 basketball-detail">
              <div class="col-2 text-center">
                <span class="d-block quarter-title">Q1</span>
                <span class="d-block mb-2">{{ eventInfo.home_detailed_score.quarter_1 }}</span>
                <span class="d-block mb-2">{{ eventInfo.away_detailed_score.quarter_1 }}</span>
              </div>
              <div class="col-2 text-center">
                <span class="d-block quarter-title">Q2</span>
                <span class="d-block mb-2">{{ eventInfo.home_detailed_score.quarter_2 }}</span>
                <span class="d-block mb-2">{{ eventInfo.away_detailed_score.quarter_2 }}</span>
              </div>
              <div class="col-2 text-center">
                <span class="d-block quarter-title">Q3</span>
                <span class="d-block mb-2">{{ eventInfo.home_detailed_score.quarter_3 }}</span>
                <span class="d-block mb-2">{{ eventInfo.away_detailed_score.quarter_3 }}</span>
              </div>
              <div class="col-2 text-center">
                <span class="d-block quarter-title">Q4</span>
                <span class="d-block mb-2">{{ eventInfo.home_detailed_score.quarter_4 }}</span>
                <span class="d-block mb-2">{{ eventInfo.away_detailed_score.quarter_4 }}</span>
              </div>
              <div class="col-2 text-center">
                <span class="d-block quarter-title">OT</span>
                <span class="d-block mb-2">
                  {{ eventInfo.home_detailed_score.quarter_over_time }}
                </span>
                <span class="d-block mb-2">
                  {{ eventInfo.away_detailed_score.quarter_over_time }}
                </span>
              </div>
              <div class="col-2 text-center"></div>
            </div>
          </b-tab>
          <b-tab v-if="eventInfo.event_type === 'football' && isLiveEvent" title="Istatistikler">
            <Statistics :eventId="eventId"></Statistics>
          </b-tab>
          <b-tab :active="!isLiveEvent" title="Oranlar">
            <Odds :eventInfo="eventInfo" :eventId="eventId"></Odds>
          </b-tab>
          <b-tab v-if="eventInfo.event_type === 'football'" title="Kadrolar">
            <Lineups :eventId="eventId"></Lineups>
          </b-tab>
          <b-tab v-if="eventInfo.event_type === 'football'" @click="showStanding = true" title="Puan Durumu">
            <Standing v-if="showStanding"
                      :eventInfo="eventInfo"
                      :leagueId="eventInfo.league_id"></Standing>
          </b-tab>
        </b-tabs>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import LiveDetails from '@/components/event/LiveDetails.vue';
import Statistics from '@/components/event/Statistics.vue';
import Lineups from '@/components/event/Lineups.vue';
import Odds from '@/components/event/Odds.vue';
import Standing from '@/components/league/Standing.vue';

export default {
  name: 'Detail',
  props: ['eventId', 'isLiveEvent'],
  components: {
    LiveDetails, Statistics, Lineups, Standing, Odds,
  },
  data() {
    return {
      showStanding: false,
      interval: '',
    };
  },
  computed: {
    ...mapState({
      eventInfo: (state) => state.eventList.eventInfo,
    }),
  },
  async created() {
    await this.getInfo();
    this.interval = window.setInterval(() => this.getInfo(), 60000);
  },
  beforeDestroy() {
    clearInterval(this.interval);
  },
  methods: {
    closeDetail() {
      this.$emit('close');
    },
    async getInfo() {
      await this.$store.dispatch('eventList/getEventInfo', this.eventId);
    },
  },
};
</script>

<style scoped>
</style>
