<template>
  <div v-if="eventStatistics !== null && typeof eventStatistics.statistics !== 'undefined'"
       class="row">
    <div class="col-3">
      <span class="text-center d-block statistics-home"
            v-for="(item, index) in eventStatistics.statistics.home" :key="index">
        {{ item.value === null ? '-' : item.value }}
      </span>
    </div>
    <div class="col-6">
      <span class="text-center d-block statistic-type"
            v-for="(item, index) in eventStatistics.statistics.home" :key="index">
        {{ item.type }}
      </span>
    </div>
    <div class="col-3">
      <span class="text-center d-block statistics-away"
            v-for="(item, index) in eventStatistics.statistics.away" :key="index">
        {{ item.value === null ? '-' : item.value }}
      </span>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex';

export default {
  name: 'Statistics',
  props: ['eventId'],
  computed: {
    ...mapState({
      eventStatistics: (state) => state.eventList.eventStatistics,
    }),
  },
  data() {
    return {
      interval: '',
    };
  },
  created() {
    this.getStatistics();
    this.interval = window.setInterval(() => this.getStatistics(), 120000);
  },
  beforeDestroy() {
    clearInterval(this.interval);
  },
  methods: {
    getStatistics() {
      this.$store.dispatch('eventList/getEventStatistics', this.eventId);
    },
  },
};
</script>

<style scoped>
</style>
