import Vue from 'vue';
import VueRouter from 'vue-router';
import LiveScore from '@/views/LiveScore.vue';
import Home from '@/views/Home.vue';
import Login from '@/views/Login.vue';
import FeedList from '@/views/FeedList.vue';

Vue.use(VueRouter);

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home,
  },
  {
    path: '/live-score',
    name: 'LiveScore',
    component: LiveScore,
  },
  {
    path: '/feeds',
    name: 'FeedList',
    component: FeedList,
  },
  {
    path: '/login',
    name: 'Login',
    component: Login,
  },
];

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
  scrollBehavior() {
    return { x: 0, y: 0 };
  },
});

export default router;
