<template>
  <b-navbar toggleable="sm" type="light" variant="light">
    <b-navbar-brand href="/">
      <img src="@/assets/img/nettahmin_logo.png" height="60" alt="Logo">
      <b class="pl-lg-2 ml-3">Net Tahmin Panel</b>
    </b-navbar-brand>

    <b-navbar-toggle target="nav-collapse"></b-navbar-toggle>

    <b-collapse id="nav-collapse" is-nav>
      <b-navbar-nav class="m-auto menu-links">
        <b-nav-item href="/">
          <i class="fas fa-newspaper"></i> Bülten
        </b-nav-item>
        <b-nav-item href="/live-score">
          <i class="fas fa-stopwatch"></i> Canlı Maçlar
        </b-nav-item>
        <b-nav-item href="/feeds">
          <i class="fas fa-binoculars"></i> Tahminlerim
        </b-nav-item>
      </b-navbar-nav>

      <b-navbar-nav class="ml-auto">
        <b-nav-item href="#" @click="logout">Çıkış Yap</b-nav-item>
      </b-navbar-nav>
    </b-collapse>
  </b-navbar>
</template>

<script>
export default {
  name: 'Menu',
  methods: {
    logout() {
      this.$session.destroy();
      this.$router.go(0);
    },
  },
};
</script>

<style scoped>
</style>
