<template>
  <b-modal id="betModal" v-show="showBettingPopup" hide-footer title="Tahmin Yap">
    <div v-if="typeof selectedBet.event !== 'undefined'" class="row">
      <div class="col-8 text-left">
        <span class="d-block">{{ selectedBet.event.event_date | moment('DD-MM-YYYY HH:mm') }}</span>
        <span class="d-block">
          <span v-if="selectedBet.is_live" class="minute">
            <img class="live-icon" src="@/assets/img/red_dot.gif" />
            <span v-if="selectedBet.event.live_status === 'HT' || selectedBet.event.live_status === 'FT'">
              {{ selectedBet.event.live_status }}
            </span>
            <span v-else>{{ selectedBet.event.live_status }} {{ selectedBet.event.current_minute }}</span>
          </span>
          {{ selectedBet.event.home_team_name }}
          {{ selectedBet.event.home_team_score }} -
          {{ selectedBet.event.away_team_score }}
          {{ selectedBet.event.away_team_name }}
        </span>
      </div>
      <div v-if="typeof selectedBet.odd.odds_value !== 'undefined'" class="col-4 text-right">
        <span class="d-block font-weight-bold">
          {{ selectedBet.odd.group_name }} {{ selectedBet.odd.odds_group_desc }}
          {{ selectedBet.odd.odds_desc }}
        </span>
        <span class="d-block bg-yellow text-white ml-auto p-1 font-weight-bold">
          Oran: {{ parseFloat(selectedBet.odd.odds_value).toFixed(2) }}
        </span>
      </div>
    </div>
    <b-form class="mt-4">
      <b-row v-if="selectedBet.is_live">
        <b-col>
          <b-form-group
            class="sm-input"
            label="Oran Grubu Seç:"
          >
            <b-form-select value-field="id"
                           text-field="group_name"
                           @change="getLiveBetOdds"
                           v-model="customOdd.group_id"
              :options="liveBetOddsGroups" size="sm" class="mb-3"></b-form-select>
          </b-form-group>
        </b-col>
        <b-col>
          <b-form-group
            class="sm-input"
            label="Bahis Seç:"
          >
            <b-form-select :options="liveBetOdds"
                           value-field="odds_desc"
                           text-field="odds_desc"
              v-model="customOdd.odds_desc" size="sm" class="mb-3"></b-form-select>
          </b-form-group>
        </b-col>
        <b-col>
          <b-form-group
            label="Limit:"
          >
            <b-form-input v-model="customOdd.odds_group_desc" size="sm"></b-form-input>
          </b-form-group>
        </b-col>
      </b-row>
      <b-form-group>
        <b-form-checkbox
          id="isVip"
          v-model="feedObject.is_vip"
          name="isVip">
          <img class="pr-1" src="@/assets/img/vip_icon.png"/>
          <span class="text-with-icon font-weight-bold">VIP</span>
        </b-form-checkbox>
      </b-form-group>
      <b-form-group
        class="sm-input"
        label="Güven:"
      >
        <b-form-input class="d-inline-block mr-1"
          v-model="feedObject.stake" min="1" max="10" size="sm"
          type="number"
        ></b-form-input>
        <span class="d-inline-block"> / 10</span>
      </b-form-group>
      <b-form-group
        label="Yorum:"
        label-for="comment-input"
      >
        <b-form-textarea
          @keyup="charCount"
          v-model="feedObject.feed_comment"
          id="comment-input"
          rows="3"
          maxlength="200"
          max-rows="6"
        ></b-form-textarea>
        <span>{{ commentTotalChar }} / 200</span>
      </b-form-group>

      <b-form-group class="text-center">
        <b-button @click="submitFeed" type="button" variant="primary">
          <div v-if="loader" class="mr-1 d-inline-block">
            <b-spinner small label="Loading..."></b-spinner>
          </div>
          Gönder
        </b-button>
      </b-form-group>
    </b-form>
  </b-modal>
</template>

<script>
import { mapState } from 'vuex';

export default {
  name: 'BettingPopup',
  props: ['selectedBet'],
  data() {
    return {
      commentTotalChar: 0,
      loader: false,
      customOdd: {
        group_id: 0,
        odds_desc: null,
        odds_group_desc: null,
      },
    };
  },
  computed: {
    ...mapState({
      feedObject: (state) => state.eventBet.feedObject,
      showBettingPopup: (state) => state.showBettingPopup,
      liveBetOddsGroups: (state) => state.eventBet.liveBetOddsGroups,
      liveBetOdds: (state) => state.eventBet.liveBetOdds,
    }),
  },
  watch: {
    selectedBet: {
      handler(newVal) {
        if (newVal.is_live && this.liveBetOddsGroups.length < 1) {
          this.$store.dispatch('eventBet/getLiveBetOddsGroups');
        }
      },
      deep: true,
    },
  },
  methods: {
    charCount() {
      this.commentTotalChar = this.feedObject.feed_comment.length;
    },
    getLiveBetOdds() {
      this.$store.dispatch('eventBet/getLiveBetOddsByGroupId', this.customOdd.group_id);
    },
    async submitFeed() {
      this.loader = true;
      const eventId = this.selectedBet.event.id;
      const oddItem = {
        odd_id: this.selectedBet.odd.id,
        event_id: eventId,
        odd_value: this.selectedBet.odd.odds_value,
        is_live_bet: this.selectedBet.is_live,
        custom_odd: this.customOdd,
      };

      this.feedObject.bets = [];
      this.feedObject.event_info = JSON.parse(JSON.stringify(this.selectedBet.event));
      this.feedObject.bets.push(oddItem);

      await this.$store.dispatch('eventBet/addFeed', this.feedObject);
      this.loader = false;
      this.$bvModal.hide('betModal');
    },
  },
};
</script>

<style scoped>

</style>
