<template>
  <section class="login-form mt-5">
    <div class="container">
      <div class="row justify-content-center">
        <div class="col-12">
          <img class="logo" src="@/assets/img/nettahmin_logo.svg">
        </div>
      </div>
    </div>
    <div class="container">
      <div class="row justify-content-center">
        <div class="col-md-8">
          <div class="card">
            <div class="card-header">Nettahmin Panel Giriş</div>
            <div class="card-body">
              <form>
                <div class="form-group row">
                  <label for="email_address" class="col-md-4 col-form-label text-md-right">E-Mail Address</label>
                  <div class="col-md-6">
                    <input type="text" v-model="loginObject.email"
                           id="email_address" class="form-control" name="email-address" required autofocus>
                  </div>
                </div>
                <div class="form-group row">
                  <label for="password" class="col-md-4 col-form-label text-md-right">Password</label>
                  <div class="col-md-6">
                    <input type="password" v-model="loginObject.password"
                           id="password" class="form-control" name="password" required>
                  </div>
                </div>
                <div class="col-md-12 m-auto">
                  <button @click="loginUser" :disabled="loader" type="button" class="btn btn-primary">
                    <div v-if="loader" class="mr-1 d-inline-block">
                      <b-spinner small label="Loading..."></b-spinner>
                    </div>
                    Login
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import { mapState } from 'vuex';

export default {
  name: 'Login',
  computed: {
    ...mapState({
      isUserLoggedIn: (state) => state.user.isUserLoggedIn,
      loginObject: (state) => state.user.loginObject,
    }),
  },
  data() {
    return {
      loader: false,
    };
  },
  async beforeMount() {
    if (this.isUserLoggedIn) {
      await this.$router.push('/');
    }
  },
  methods: {
    async loginUser() {
      this.loader = true;
      await this.$store.dispatch('user/loginUser', this.loginObject);
      this.loader = false;

      if (this.isUserLoggedIn) {
        await this.$router.push('/');
      }
    },
  },
};
</script>

<style scoped>

</style>
