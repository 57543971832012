import HttpService from '@/services/http-service';

export default {
  namespaced: true,
  state: {
    showBettingPopup: false,
    selectedBet: {},
    liveBetOddsGroups: [],
    liveBetOdds: [],
    feedList: [],
    feedObject: {
      event_info: {},
      feed_comment: '',
      is_vip: false,
      vip_value: 0,
      stake: 0,
      bets: [],
    },
  },
  getters: {
    feedObject(state) {
      return state.feedObject;
    },
    selectedBet(state) {
      return state.selectedBet;
    },
    showBettingPopup(state) {
      return state.showBettingPopup;
    },
    getFeedList(state) {
      return state.feedList;
    },
    getLiveBetOddsGroups(state) {
      return state.liveBetOddsGroups;
    },
    getLiveBetOddsByGroupId(state) {
      return state.liveBetOdds;
    },
  },
  actions: {
    setSelectedBet: ({ commit }, newValue) => {
      commit('SET_SELECTED_BET', newValue);
    },
    getLiveBetOddsGroups({ commit }) {
      return HttpService.get(`${process.env.VUE_APP_API_URL}/api/v1/events/live-bet-odds-groups`, {})
        .then((res) => {
          if (res.status === 'APPROVED') {
            commit('SET_LIVE_BET_ODDS_GROUPS', res.data);
          }
        })
        .catch((err) => {
          commit('CHANGE_ALERT_MESSAGE', { alertMessage: err, type: 'danger' }, { root: true });
        });
    },
    getLiveBetOddsByGroupId({ commit }, id) {
      return HttpService.get(`${process.env.VUE_APP_API_URL}/api/v1/events/live-bet-odds/${id}`, {})
        .then((res) => {
          if (res.status === 'APPROVED') {
            commit('SET_LIVE_BET_ODDS', res.data);
          }
        })
        .catch((err) => {
          commit('CHANGE_ALERT_MESSAGE', { alertMessage: err, type: 'danger' }, { root: true });
        });
    },
    getFeedByUser({ commit }, data) {
      return HttpService.get(`${process.env.VUE_APP_API_URL}/api/v1/panel/feed/list/${data}`, {})
        .then((res) => {
          if (res.status === 'APPROVED') {
            commit('SET_FEED_LIST', res.data);
          }
        })
        .catch((err) => {
          commit('CHANGE_ALERT_MESSAGE', { alertMessage: err, type: 'danger' }, { root: true });
        });
    },
    addFeed({ commit }, data) {
      return HttpService.post(`${process.env.VUE_APP_API_URL}/api/v1/panel/feed`, { data })
        .then((res) => {
          if (res.status === 'APPROVED') {
            commit('CHANGE_POPUP_STATUS', false);
            commit('EMPTY_SELECTED_BET');
            commit('EMPTY_FEED_OBJECT');
            commit('CHANGE_ALERT_MESSAGE', { alertMessage: 'Feed added successfully', type: 'success' }, { root: true });
          }
        })
        .catch((err) => {
          commit('CHANGE_ALERT_MESSAGE', { alertMessage: err, type: 'danger' }, { root: true });
        });
    },
  },
  mutations: {
    SET_FEED_LIST: (state, data) => {
      state.feedList = data;
    },
    SET_LIVE_BET_ODDS_GROUPS: (state, data) => {
      state.liveBetOddsGroups = data;
    },
    SET_LIVE_BET_ODDS: (state, data) => {
      state.liveBetOdds = data;
    },
    EMPTY_SELECTED_BET: (state) => {
      state.selectedBet = {};
    },
    EMPTY_FEED_OBJECT: (state) => {
      state.feedObject = {
        event_info: {},
        feed_comment: '',
        is_vip: false,
        vip_value: 0,
        bets: [],
      };
    },
    SET_SELECTED_BET: (state, data) => {
      state.selectedBet = data;
      state.showBettingPopup = true;
    },
    CHANGE_POPUP_STATUS: (state, data) => {
      state.showBettingPopup = data;
    },
  },
};
