import HttpService from '../../services/http-service';

export default {
  namespaced: true,
  state: {
    leagueStanding: [],
  },
  getters: {
    getStanding(state) {
      return state.leagueStanding;
    },
  },
  actions: {
    getStanding({ commit }, data) {
      const { leagueId, eventId } = data;
      return HttpService.get(`${process.env.VUE_APP_API_URL}/api/v1/events/standing/${leagueId}/${eventId}`, {})
        .then((res) => {
          if (res.status === 'APPROVED') {
            commit('SET_LEAGUE_STANDING', res.data[0]);
          }
        })
        .catch((err) => console.log(err));
    },
  },
  mutations: {
    SET_LEAGUE_STANDING: (state, data) => {
      state.leagueStanding = data;
    },
  },
};
