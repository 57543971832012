import Vue from 'vue';
import { BootstrapVue, BootstrapVueIcons } from 'bootstrap-vue';
import VueMoment from 'vue-moment';
import VueSession from 'vue-session';
import store from './store';
import App from './App.vue';
import router from './router';

import 'bootstrap/dist/css/bootstrap.css';
import 'bootstrap-vue/dist/bootstrap-vue.css';
import 'bootstrap-vue/dist/bootstrap-vue-icons.min.css';
import 'vue-date-pick/dist/vueDatePick.css';
import '@/assets/css/paper-dashboard.css';
import '@/assets/css/main.css';
import '@fortawesome/fontawesome-free/css/all.css';
import '@fortawesome/fontawesome-free/js/all';

Vue.config.productionTip = false;

Vue.use(BootstrapVue);
Vue.use(BootstrapVueIcons);
Vue.use(VueMoment);
Vue.use(VueSession, { persist: true });

Vue.prototype.$session.start();

router.beforeEach(async (to, from, next) => {
  if (typeof Vue.prototype.$session.get('ntjwt') !== 'undefined') {
    await store.dispatch('user/validateToken').then(() => {
      if (!store.getters['user/isUserLoggedIn']) {
        Vue.prototype.$session.destroy();
        router.push('/login');
      }
    });

    if (typeof Vue.prototype.$session.get('ntjwt') === 'undefined') {
      Vue.prototype.$session.destroy();
      await router.push('/login');
    }
  }

  next();
});

new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount('#app');
