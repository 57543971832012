<template>
  <div class="live-score">
    <div class="container-fluid">
      <div class="row">
        <div class="col-12">
          <b-nav pills>
            <b-nav-item v-for="(item, index) in eventTypes" :key="index"
                        @click="getLiveEvents(item.id)"
                        :active="selectedType === item.id">
              {{ item.type_name }}
            </b-nav-item>
          </b-nav>
        </div>
      </div>
      <div class="row event-list-section mb-5 mt-3 position-relative">
        <div v-if="liveEventList.length < 1" class="col-12">
          <span>Canlı maç bulunmamaktadır.</span>
        </div>
        <div id="event-list" class="col-12">
          <div v-for="(item, index) in liveEventList" :key="index">
            <div class="league-header text-left">
              <span class="country-flag"><img :src="item.country_flag"></span>
              <span class="title">{{ item.country_name }} - {{ item.league_name }}</span>
            </div>
            <div v-if="!isMobile">
              <div v-for="(event, index2) in item.events" :key="index2"
                   :class="{ 'active': selectedEvent === event.id }"
                   class="row event-detail desktop m-0">
                <div class="col-2 text-center">
                  <span class="minute">
                    <img class="live-icon" src="@/assets/img/red_dot.gif" />
                    <span v-if="event.live_status === 'HT' || event.live_status === 'FT'">
                      {{ event.live_status }}
                    </span>
                    <span v-else-if="event.current_minute === null">
                      {{ event.event_date | moment("from", "now", true) | moment('mm') }}
                    </span>
                    <span v-else>{{ event.live_status }} {{ event.current_minute }}</span>
                  </span>
                </div>
                <div class="col-3 text-right">
                  <span class="team-name">
                    <span v-if="event.home_team_red_card_count > 0" class="red-card">
                      {{ event.home_team_red_card_count }}
                    </span>
                    <span class="team-logo"><img :src="event.home_team_logo"></span>
                    {{ event.home_team_name }}
                  </span>
                </div>
                <div class="col-2 text-center event-score">
                  <span @click="openEventDetail(event.id)">
                    {{ event.home_team_score }} - {{ event.away_team_score }}
                  </span>
                </div>
                <div class="col-3 text-left">
                  <span class="team-name">
                    <span class="team-logo"><img :src="event.away_team_logo"></span>
                    {{ event.away_team_name }}
                    <span v-if="event.away_team_red_card_count > 0" class="red-card">
                      {{ event.away_team_red_card_count }}
                    </span>
                  </span>
                </div>
                <div class="col-2 event-btn text-right">
                  <b-button @click="openBetPopup(index, index2)" variant="info">Tahmin Yap</b-button>
                </div>
              </div>
            </div>
            <div v-if="isMobile">
              <div v-for="(event, index2) in item.events" :key="index2"
                   :class="{ 'active': selectedEvent === event.id }"
                   class="row event-detail mobile m-0">
                <div class="col-3 text-center">
                <span class="minute">
                  <img class="live-icon" src="@/assets/img/red_dot.gif" />
                  <span v-if="event.live_status === 'HT' || event.live_status === 'FT'">
                    {{ event.live_status }}
                  </span>
                  <span v-else-if="event.current_minute === null">
                    {{ event.event_date | moment("from", "now", true) | moment('mm') }}
                  </span>
                  <span v-else>{{ event.live_status }} {{ event.current_minute }}</span>
                  <b-button @click="openBetPopup(index, index2)" variant="info">Tahmin Yap</b-button>
                </span>
                </div>
                <div class="col-6 text-right">
                <span class="team-name d-block text-left">
                  <span class="team-logo"><img :src="event.home_team_logo"></span>
                  {{ event.home_team_name }}
                  <span v-if="event.home_team_red_card_count > 0" class="red-card">
                    {{ event.home_team_red_card_count }}
                  </span>
                </span>
                  <span class="team-name d-block text-left">
                  <span class="team-logo"><img :src="event.away_team_logo"></span>
                  {{ event.away_team_name }}
                  <span v-if="event.away_team_red_card_count > 0" class="red-card">
                    {{ event.away_team_red_card_count }}
                  </span>
                </span>
                </div>
                <div class="col-3 text-center event-score">
                <span @click="openEventDetail(event.id)">
                  <span class="d-block">{{ event.home_team_score }}</span>
                  <span class="d-block">{{ event.away_team_score }}</span>
                </span>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div v-if="selectedEvent > 0" :key="selectedEvent" class="col-lg-4 col-sm-12 event-detail-section">
          <eventDetail v-on:close="closeEventDetail" :eventId="selectedEvent" :isLiveEvent="true"></eventDetail>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import eventDetail from '@/components/event/Detail.vue';

export default {
  name: 'LiveScore',
  components: { eventDetail },
  async mounted() {
    await this.$store.dispatch('eventList/getEventTypes');
    this.getLiveEvents(this.eventTypes[0].id);
    this.interval = window.setInterval(() => this.updateLiveEvents(this.selectedType), 60000);
  },
  async beforeMount() {
    if (!this.isUserLoggedIn) {
      await this.$router.push('/login');
    }
  },
  beforeDestroy() {
    clearInterval(this.interval);
  },
  data() {
    return {
      selectedEvent: 0,
      selectedType: 1,
      interval: '',
    };
  },
  computed: {
    ...mapState({
      liveEventList: (state) => state.eventList.liveEventList,
      isMobile: (state) => state.isMobile,
      isUserLoggedIn: (state) => state.user.isUserLoggedIn,
      eventTypes: (state) => state.eventList.eventTypes,
    }),
  },
  methods: {
    closeEventDetail() {
      document.getElementById('event-list').className = 'col-12';
      this.selectedEvent = 0;
    },
    updateLiveEvents(eventType) {
      this.selectedType = eventType;
      this.$store.dispatch('eventList/getLiveEvents', eventType);
    },
    getLiveEvents(eventType) {
      this.selectedEvent = 0;
      document.getElementById('event-list').className = 'col-12';
      this.selectedType = eventType;
      this.$store.dispatch('eventList/getLiveEvents', eventType);
    },
    openEventDetail(eventId) {
      document.getElementById('event-list').className = 'col-lg-8';
      this.selectedEvent = eventId;
      window.scrollTo(0, 0);
    },
    openBetPopup(groupIndex, eventIndex) {
      const selectedBet = {
        is_live: true,
        event: this.liveEventList[groupIndex].events[eventIndex],
        odd: {},
      };

      this.$store.dispatch('eventBet/setSelectedBet', selectedBet);
      this.$bvModal.show('betModal');
    },
  },
};
</script>

<style>
</style>
